/**
 * @name 设定项目全局的配置信息
 */
module.exports = {
    /**
     * @type {String} 项目标题
     */
    title: "Resister",

    /**
     * @type {String} token字段
     */
    tokenKeyName: "x-token",
    
    /**
     * @type {String} 系统标识
     */
    system: "client",

    /**
     * @type {String} 后台接口路径
     */
    baseUrl: process.env.VUE_APP_BASE_URL || "http://127.0.0.1:3000",

    /**
     * @type {String} 静态资源获取地址
     */
    assetsUrl: process.env.VUE_APP_ASSETS_URL || "http://127.0.0.1:3000",

    /**
     * @type {Array} 可允许上传文件类型
     */
    acceptImgType: [".png", ".jpg"], //图片
    acceptVideoType: [".mp4", ".3gp", ".flv", ".avi", ".mov", ".rm"], // 视频
    acceptAudioType: [".mp3", ".cda", ".wav", ".wma", ".ogg", ".flac"], // 音频
}