<template>
  <div id="app">
    <router-view />

    <el-dialog class="app-modal" :visible.sync="userLoginModal" :before-close="beforeCloseLoginModal" width="450px">
      <!-- <el-tabs v-model="activeTab">
        <el-tab-pane label="登录" name="login"></el-tab-pane>
        <el-tab-pane label="注册" name="register"></el-tab-pane>
      </el-tabs> -->
      <h2>
        <span @click="activeTab = 'login'" :class="{ active: activeTab == 'login' }">登录</span>
        <span class="separator"></span>
        <span @click="activeTab = 'register'" :class="{ active: activeTab == 'register' }">注册</span>
      </h2>
      <keep-alive>
        <user-login v-if="activeTab == 'login'" :showRegister="false" :navigator="false" @submit="submit"></user-login>
        <user-register v-if="activeTab == 'register'" :navigator="false" @submit="submit"></user-register>
      </keep-alive>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "login",
    };
  },
  computed: {
    userLoginModal: {
      get() {
        return this.$store.state.userLoginModal;
      },
      set(data) {
        return data;
      },
    },
  },
  components: {
    UserLogin: () => import("./components/user/UserLogin.vue"),
    UserRegister: () => import("./components/user/UserRegister.vue"),
  },
  created() {
    this.getOptions();
  },
  methods: {
    // 手动关闭弹窗
    beforeCloseLoginModal(done) {
      this.$store.dispatch("toggleUserLoginModal", false).then(() => {
        done();
      });
    },
    // 拉取全局的数据字典
    getOptions() {
      this.listLoading = true;
      this.$get("/dictionary/all", {
        ...this.listQuery,
      })
        .then((res) => {
          this.listLoading = false;
          if (res.code != 200) {
            return this.$notify({
              title: "失败",
              message: res.message,
              type: "error",
            });
          }
          this.list = res.data;
          this.resetOptions(res.data);
        })
        .catch((err) => {
          this.listLoading = false;
          this.$message({
            message: "网络错误，请稍后再试",
            type: "warning",
          });
          console.log(err);
        });
    },
    resetOptions(data) {
      let copyData = JSON.parse(JSON.stringify(data));
      let obj = {};
      copyData.forEach((v) => {
        obj[v.value] = v.children || [];
      });
      sessionStorage.setItem("options", JSON.stringify(obj));
      this.$store.dispatch("setOptions", JSON.stringify(obj));
    },

    // 登录成功
    submit(status) {
      if (status) this.$store.dispatch("toggleUserLoginModal", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.app-modal {
  /deep/.el-dialog__header {
    padding: 0 !important;
  }

  /deep/.el-dialog {
    border-radius: 10px;
  }

  h2 {
    text-align: center;
    font-weight: 600;

    span {
      padding: 10px 20px;
      vertical-align: middle;
      color: #666;

      &:hover {
        color: #000;
        cursor: pointer;
      }

      &.active {
        color: #000;
      }
    }

    .separator {
      display: inline-block;
      width: 1px;
      padding: 0;
      height: 18px;
      background-color: #232529;
    }
  }
}
</style>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

#app {
  font-family: PingFangSC-Regular, Microsoft Yahei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
