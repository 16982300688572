import Vue from 'vue'
import Vuex from 'vuex'
import { acceptAudioType, acceptImgType, acceptVideoType, baseUrl, assetsUrl } from '../setting';

Vue.use(Vuex)

let userInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo")) || null;
let token = localStorage.getItem("token") || null;
let role = userInfo && userInfo.type;
let options = sessionStorage.getItem("options") && JSON.parse(sessionStorage.getItem("options")) || null;

export default new Vuex.Store({
  state: {
    baseUrl,
    assetsUrl,
    geoLocation: null,
    acceptImgType,
    acceptVideoType,
    acceptAudioType,
    token: token,
    role: role == 1 ? "user" : "customer",
    userInfo,
    userLoginModal: false, // 全局的用户登录弹窗
    options, // 数据字典

    // 滚动吸顶后，内容区距离顶部的padding值
    waterfallListOffsetTop: 20,
  },
  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data || [];
    },
    SET_GEO_LOCATION(state, data) {
      console.log("SET_GEO_LOCATION: ", data);
      state.geoLocation = data;
    },
    // 设置用户登录信息
    // 其中type 1用户 2 回收员
    SET_USER_INFO(state, data) {
      let {
        token,
        userInfo
      } = data;
      let {
        type
      } = userInfo;
      state.token = token;
      state.userInfo = userInfo;
      let roleForName = type == 1 ? "user" : "customer";
      state.role = roleForName;

      // window.localStorage.setItem("userInfo", JSON.stringify(data.userInfo))
      // window.localStorage.setItem("token", token);

      localStorage.setItem("userInfo", JSON.stringify(data.userInfo))
      localStorage.setItem("token", token);

      state.userLoginModal = false;
    },
    USER_LOG_OUT(state, data) {
      state.token = null;
      state.userInfo = null;
      state.role = null;
      // localStorage.removeItem("userInfo");
      // localStorage.removeItem("token");

      localStorage.removeItem("userInfo")
      localStorage.removeItem("token")
    },
    UPDATE_USER_BASIC(state, data) {
      let originUserInfo = localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"));
      state.userInfo = Object.assign(originUserInfo, data);
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
    },
    TOGGLE_USER_LOGIN_MODAL(state, data) {
      state.userLoginModal = data;
    },
    SET_WATERFALL_OFFSET_TOP(state, data) {
      state.waterfallListOffsetTop = data + 20;
    }
  },
  actions: {
    setOptions({ commit }, data) {
      commit("SET_OPTIONS", data)
    },
    setGeoLocation({ commit }, data) {
      commit("SET_GEO_LOCATION", data)
    },
    // 用户登录
    userLogin({
      commit
    }, data) {
      commit("SET_USER_INFO", data);
    },
    // 用户退出
    userLogout({
      commit
    }, data) {
      commit("USER_LOG_OUT", data);
    },
    // 根据特定字段更新用户信息
    updateUserBasic({
      commit
    }, data) {
      commit("UPDATE_USER_BASIC", data)
    },
    toggleUserLoginModal({ commit }, data) {
      commit("TOGGLE_USER_LOGIN_MODAL", data)
    },
    setWaterfallListOffsetTop({ commit }, data) {
      commit("SET_WATERFALL_OFFSET_TOP", data);
    }
  },
  modules: {
  }
})
