import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '../store/index'
import { baseUrl, system, tokenKeyName } from '../setting'

// 定义用户弹窗弹出状态
let showUserCheckFailedServiceModal = false;
// create an axios instance

const service = axios.create({
  // baseURL: "http://192.168.1.171:3000", // url = base url + request url
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers["system"] = system
    if (store.state.token) {
      config.headers[tokenKeyName] = store.state.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code == 401 || res.code == 403) {
      if (!showUserCheckFailedServiceModal) {
        showUserCheckFailedServiceModal = true;
        MessageBox.confirm(res.message, '信息核验失败', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('userLogout').then(() => {
            sessionStorage.clear()
            localStorage.clear()
            store.dispatch("toggleUserLoginModal", true)
          })
          showUserCheckFailedServiceModal = false;
        }).catch(() => showUserCheckFailedServiceModal = false)
      }
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then(
        res => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
      .catch(err => {
        reject(err)
      })
  })
}
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function put(url, params) {
  return new Promise((resolve, reject) => {
    service.put(url, {
      params: params
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function del(url, params) {
  return new Promise((resolve, reject) => {
    service.delete(url, {
      params: params
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export default service
