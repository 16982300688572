/*
 * @Author: lixianhao 
 * @Date: 2023-02-07 12:31:06
 * @LastEditTime: 2023-05-21 10:47:32
 * @FilePath: /InspirationDesign/client/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/home",
    children: [
      // 公共
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/home/Index.vue"),
        meta: {
          title: "抵御者素材-让二次元壁纸源码素材变鲜活",
          noSideBar: true,
          pure: true,
          showFooter: true
        }
      },
      {
        path: "/community/:category",
        name: "Community",
        component: () => import("../views/community/Index.vue"),
        meta: {
          title: "资源中心",
        }
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/user/Login.vue"),
        meta: {
          title: "用户登录",
          noSideBar: true,
          pure: true,
          transparent: true
        },
      },
      {
        path: "/register",
        name: "Register",
        component: () => import("../views/user/Register.vue"),
        meta: {
          title: "用户注册",
          noSideBar: true,
          pure: true,
          transparent: true
        },
      },

      // 用户
      {
        path: "/personal",
        name: "Personal",
        component: () => import("../views/user/Personal.vue"),
        meta: {
          title: "编辑资料",
          auth: true,
          noSideBar: true
        },
      },
      {
        path: "/vip",
        name: "Vip",
        component: () => import("../views/user/Vip.vue"),
        meta: {
          title: "会员中心",
          auth: true,
          noSideBar: true
        },
      },
      {
        path: "resources",
        name: "Resources",
        redirect: "/resources/list",
        component: () => import("../views/resource/Index.vue"),
        meta: {
          title: "我发布的",
          auth: true,
        },
        children: [
          {
            path: "post",
            name: "ResourcesPost",
            component: () => import("../views/resource/Post.vue"),
            meta: {
              title: "资源发布",
              auth: true,
              noSideBar: true
            }
          },
          {
            path: "detail",
            name: "ResourcesDetail",
            component: () => import("../views/resource/Detail.vue"),
            meta: {
              title: "资源详情",
              noSideBar: true
            }
          },
          {
            path: "collect/:category?",
            name: "ResourcesCollect",
            component: () => import("../views/resource/Collect.vue"),
            meta: {
              title: "我的收藏",
              pageTitle: "收藏记录",
              auth: true,
              sideBar: "collect"
            }
          },
          {
            path: "download/:category?",
            name: "ResourcesDownload",
            component: () => import("../views/resource/Download.vue"),
            meta: {
              title: "我的下载",
              pageTitle: "下载历史",
              auth: true,
              sideBar: "download"
            }
          },
          {
            path: "list/:category?",
            name: "ResourcesList",
            component: () => import("../views/resource/List.vue"),
            meta: {
              title: "资源列表",
              auth: true,
              sideBar: "list"
            }
          },
        ]
      },
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let role = store.state.role;
  let token = store.state.token;


  // 如果角色没有跳转页面的权限，则报错403
  if (!!to.meta && !!to.meta.roles && to.meta.roles.indexOf(role) == -1) {
    return ElMessage.error("您尚不具备该权限，请联系管理员核实");
  }

  // 如果当前用户没有登录，且当前页面需要登录，直接跳转登录界面
  if (!!to.meta && to.meta.auth && !token) {
    // 如果上一个界面和当前界面的路径一样，那么单纯变更当前页面的参数
    return next({
      path: "/login",
      query: {
        redirect: from.path == to.path ? from.query : from.fullPath
      }
    })
  }

  // 修改页面标题
  document.title = (!!to.meta && to.meta.title) ? to.meta.title + "-抵御者素材" : "抵御者素材-让二次元壁纸源码素材变鲜活"

  next();
})

export default router
