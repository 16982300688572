<template>
  <div class="layout" :class="{ pure: $route.meta.pure, transparent: $route.meta.transparent }">
    <div class="head" ref="head">
      <HeaderPure v-if="$route.meta.pure"></HeaderPure>
      <Header v-else-if="!$route.meta.noHeader"></Header>
    </div>
    <div class="body-wrapper" v-if="isPure">
      <router-view></router-view>
    </div>
    <div class="body-wrapper" v-else>
      <div ref="content" class="content" :style="'width: 100%'">
        <template v-if="!isNoSideBar">
          <div class="navbar" v-if="!sideBar">
            <NavBar ref="navBar" :navScale="navScale"></NavBar>
          </div>
          <div class="navbar" v-if="sideBar">
            <NavBarPersonal ref="navBar" :navScale="navScale"></NavBarPersonal>
          </div>
        </template>
        <router-view></router-view>
      </div>
    </div>
    <Footer v-if="isFooter"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      navScale: false, // 是否缩放导航
      navBarOffsetTop: undefined,
      navBarClientHeight: undefined
    };
  },
  computed: {
    isPure() { return this.$route.meta.pure },
    isNoSideBar() { return this.$route.meta.noSideBar },
    sideBar() { return this.$route.meta.sideBar },
    isFooter() { return this.$route.meta.showFooter }
  },
  components: {
    Header: () => import("../components/common/Header.vue"),
    HeaderPure: () => import("../components/common/HeaderPure.vue"),
    NavBar: () => import("../components/common/NavBar.vue"),
    NavBarPersonal: () => import("../components/common/NavBarPersonal.vue"),
    Footer: () => import("../components/common/Footer.vue"),
  },
  methods: {
    ...mapActions(["setWaterfallListOffsetTop"]),
    scrollEvent(e) {
      if (this.$refs.navBar && this.$refs.navBar.$refs.sideBar) {
        if (this.navBarOffsetTop === undefined) {
          this.navBarClientHeight = this.$refs.navBar.$el.clientHeight
          this.navBarOffsetTop = this.$refs.navBar.$refs.sideBar.offsetTop;
        }
        let isScale = e.target.scrollTop > this.navBarOffsetTop
          ? true
          : false;

        // 内边距-上：导航栏高度+顶部高度
        this.setWaterfallListOffsetTop(
          isScale
            ? this.navBarClientHeight
            : 0
        );
        this.navScale = isScale;
      }
    }
  },
  mounted() {
    this.$refs.content && this.$refs.content.addEventListener("scroll", this.scrollEvent);
  },
  beforeDestroy() {
    this.$refs.content && this.$refs.content.removeEventListener("scroll", this.scrollEvent);
  },
  watch: {
    "$route.path"() {
      this.navBarOffsetTop = undefined;
      this.navBarClientHeight = undefined
    },
    isNoSideBar(newV) {
      if (newV) {
        this.$nextTick(() => {
          console.log(newV, this.$refs.content)
          this.$refs.content && this.$refs.content.removeEventListener("scroll", this.scrollEvent);
        })
      } else {
        this.$nextTick(() => {
          console.log(newV, this.$refs.content)
          this.$refs.content && this.$refs.content.addEventListener("scroll", this.scrollEvent);
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.layout {
  height: 100vh;
  background-color: #1d1f22;

  .head {
    background-color: #232529;
    border-bottom: 1px solid #000;
  }

  .body-wrapper {
    display: flex;
    height: calc(100% - 61px);
  }

  .side {
    // width: 210px;
    border-right: 1px solid #000;
    height: 100%;
    overflow: auto;
  }

  .content {
    // width: calc(100% - 210px);
    // max-width: 1575px; // 235 * 6 + 25 * 5 + 40（左右20边距）
    height: 100%;
    overflow: auto;
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 3px;
    }
  }

  &.pure {
    height: inherit;
    min-height: 100vh;
    background-color: #fff;

    .head {
      background-color: transparent;
      border-bottom: none;
    }

    .body-wrapper {
      width: 100%;
      display: block;
      height: inherit;
    }
  }

  &.transparent {
    background-image: url("../assets/imgs/personal_bg.png");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;

    /deep/.header {
      background-color: transparent;
    }
  }

  .navbar {
    position: relative;
    width: 100%;
  }
}
</style>
